import React from "react";

const TextInput = ({ name, labelText, onChange }) => {
  return (
    <div className="input-group">
      <label
        htmlFor={"input--" + name}
        style={{ textAlign: "center", color: "white" }}
      >
        {labelText}
      </label>
      <input
        id={"input--" + name}
        className="input input--text"
        onChange={onChange}
      />
    </div>
  );
};

export default TextInput
