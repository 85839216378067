import TextInput from "./TextInput";
import React, { useState } from "react";
import Checkbox from "./Checkbox";
import TextAreaInput from "./TextAreaInput";

const TileInput = ({ handleChange }) => {
  const [localDisplayName, setLocalDisplayName] = useState(null);
  const [localShowDisplayName, setLocalShowDisplayName] = useState(true);
  const [localMessage, setLocalMessage] = useState(null);
  const [localShowMessage, setLocalShowMessage] = useState(true);

  const handleSubmit = () => {
    handleChange({
      displayName: localDisplayName,
      message: localMessage,
      showDisplayName: localShowDisplayName,
      showMessage: localShowMessage,
      completedSignin: true,
    });
  };

  return (
    <div className="button-wrapper button-wrapper--input">
      <div className="input-wrapper">
        <TextInput
          labelText="Your Name"
          name="name"
          onChange={e => setLocalDisplayName(e.target.value)}
        />

        <Checkbox
          labelText="Display your name publicly"
          name="showName"
          onChange={e => setLocalShowDisplayName(e.target.checked)}
        />

        <TextAreaInput
          labelText="Add a message"
          name="message"
          onChange={e => setLocalMessage(e.target.value)}
        />

        <Checkbox
          labelText="Display your message publicly"
          name="showMessage"
          onChange={e => setLocalShowMessage(e.target.checked)}
        />
      </div>
      <button onClick={handleSubmit}>+ Add me to the Wall</button>
    </div>
  );
};

export default TileInput;
