/**
 * @param {HTMLElement} wallRef
 */
const scrollWall = wallRef => {
  const tileWrappers = wallRef.querySelectorAll(".tile-wrapper");
  if (tileWrappers.length === 0) return;
  const height = tileWrappers[0].getBoundingClientRect().height;
  const yVel = .3;
  window.y = window.y || 0;
  let isTicking = true;

  const draw = () => {
    window.y -= yVel;
    for (let i = 0; i < tileWrappers.length; i++) {
      if (window.y < -height) window.y = 0;
      tileWrappers[i].style.transform = `translateY(${window.y + i * height}px)`;
    }

    if (isTicking) window.requestAnimationFrame(draw);
  };

  window.requestAnimationFrame(draw);

  return () => {
    isTicking = false;
  };
};

export default scrollWall;
