const firebaseConfig = {
  apiKey: "AIzaSyBz5qRCG99rZ8kOae2citDGL4lVyIrTMJo",
  authDomain: "remembering-roger.firebaseapp.com",
  databaseURL: "https://remembering-roger.firebaseio.com",
  projectId: "remembering-roger",
  storageBucket: "remembering-roger.appspot.com",
  messagingSenderId: "870666936803",
  appId: "1:870666936803:web:034841d2e0b56dae3f00d6",
  measurementId: "G-F5L59KG71N"
};


export default firebaseConfig;
