import React, { useEffect } from "react";

import Auth from "./Auth";
import useSupporters from "../hooks/useSupporters";
import Tile from "./Tile";
import scrollWall from "../utils/scrollWall";
import useDimensions from "../hooks/useDimensions";

const Wall = () => {
  const [supporters] = useSupporters();
  const [wallRef, { height }] = useDimensions();
  useEffect(() => scrollWall(wallRef.current), [supporters, wallRef]);

  const sortByDate = (a, b) => supporters[a].created - supporters[b].created;
  const Tiles = Object.keys(supporters)
    .sort(sortByDate)
    .filter(supporterID => supporters[supporterID].displayName)
    .map(supporterID => (
      <Tile {...supporters[supporterID]} key={supporterID} />
    ));

  let TileWrappers = [
    <div className="tile-wrapper">{Tiles}</div>,
    <div className="tile-wrapper">{Tiles}</div>,
  ];

  const rowCount = Math.ceil(Tiles.length / 5);
  let repeatCount = 2;
  if (height && rowCount > 0) {
    console.log("repeatCount:", repeatCount, "height:", height, 'rowCount:', rowCount);
    console.log(repeatCount * rowCount * 82);

    while (repeatCount * rowCount * 82 < height) {
      console.log("loop");
      TileWrappers = TileWrappers.concat(TileWrappers);
      repeatCount++;
    }
  }

  return (
    <div className="wall" ref={wallRef}>
      {TileWrappers}
      <Auth />
    </div>
  );
};

export default Wall;
