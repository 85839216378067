import firebase from "../firebase";
import { useState, useEffect } from "react";

const supportersCollection = firebase.firestore().collection("supporters");
const useSupporters = () => {
  const [supporters, setSupporters] = useState({});

  useEffect(() => {
    return supportersCollection.onSnapshot(supportersSnapshot => {
      const supporters = {};
      supportersSnapshot.forEach(doc => {
        const supporter = doc.data();
        supporters[supporter.uid] = supporter;
      });
      setSupporters(supporters);
    });
  }, []);

  const updateSupporter = (uid, data) => {
    const supporter = supporters[uid] || {};
    supportersCollection.doc(uid).set({ ...supporter, ...data });
  };

  return [supporters, updateSupporter];
};

export default useSupporters;
