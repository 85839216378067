import React, { useState } from "react";
import * as firebase from "firebase";
import * as firebaseui from "firebaseui";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import transformUserData from "../utils/transformUserData";
import TileInput from "./TileInput";
import useSupporters from "../hooks/useSupporters";
import { useAuth } from "../hooks/useAuth";

const uiConfig = {
  signInSuccessUrl: "/success",
  signInFlow: "popup",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  callbacks: { signInSuccessWithAuthResult: () => false },
  tosUrl: "/tos",
  privacyPolicyUrl: function() {
    window.location.assign("/privacy-policy");
  }
};

const Auth = () => {
  const [user] = useAuth();
  const [isJoining, setIsJoining] = useState(false);
  const [supporters, setSupporter] = useSupporters();
  const isSupportersInitialized = Object.keys(supporters).length > 0;

  const supporter = user && supporters[user.uid] ? supporters[user.uid] : {};
  const { completedSignin } = supporter;

  const needsInput = !completedSignin && isSupportersInitialized;
  const signedIn = !!user && isSupportersInitialized;

  const setInput = inputData => {
    setSupporter(user.uid, {
      ...transformUserData(user),
      ...inputData
    });
  };

  let output;

  if (!signedIn && needsInput && !isJoining) {
    //User not signed in (default state)
    output = (
      <div className="button-wrapper">
        <p style={{ textAlign: "center", color: "white" }}>
          Want to show support? Add your photo
        </p>
        <button onClick={() => setIsJoining(true)}>+ Add</button>
      </div>
    );
  }

  if (!signedIn && needsInput && isJoining) {
    //User selecting auth
    output = (
      <div className="button-wrapper">
        <p style={{ textAlign: "center", color: "white" }}>
          Sign in to use your photo
        </p>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    );
  }

  if (signedIn && needsInput) {
    //User inputting message and name
    output = <TileInput handleChange={setInput} />;
  }

  if (signedIn && !needsInput) {
    output = null;
  }

  return (
    <div
      className={
        "signin-wrapper" + (!signedIn || needsInput ? " --signin-ui" : "")
      }
    >
      {output}
    </div>
  );
};

export default Auth;
