import React from "react";

const TextAreaInput = ({ name, labelText, onChange }) => {
  return (
    <div className="input-group">
      <label
        htmlFor={"input--" + name}
        style={{ textAlign: "center", color: "white" }}
      >
        {labelText}
      </label>
      <textarea
        id={"input--" + name}
        rows={3}
        className="input input--textarea"
        onChange={onChange}
      />
    </div>
  );
};

export default TextAreaInput
