export default user => {
  return {
    uid: user.uid,
    isAnonymous: user.isAnonymous,
    displayName: user.displayName,
    message: '',
    showMessage: false,
    showDisplayName: false,
    photoURL: user.photoURL,
    completedSignin: false,
    approved: true,
    created: Date.now()
  };
}
