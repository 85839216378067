import React from "react";

import "../firebase";
import Wall from "./Wall";

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          Remembering Roger &nbsp;
          <span className="small">#rememberingroger</span>
        </h1>
        <div className="top-row-wrapper">
          <div className="top-row">
            <Wall />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
