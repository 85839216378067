import React from "react";
import { useAuth } from "../hooks/useAuth";

const Tile = ({
  uid,
  isAnonymous,
  created,
  photoURL,
  showDisplayName,
  displayName,
  showMessage,
  approved,
  message
}) => {
  const [user] = useAuth();
  const isCurrentUser = user && user.uid === uid;
  const photoSrc =
    photoURL ||
    `https://ui-avatars.com/api/?background=1e1e1e&color=fff&name=${displayName.replace(
      /\s/g,
      "+"
    )}`;

  return (
    <>
      <div
        className={"tile" + (isCurrentUser ? " tile--current" : "")}
        key={uid}
      >
        <div className="tile__image-wrap">
          {approved && <img className="tile__image" src={photoSrc} />}
        </div>
        {approved && (showMessage || showDisplayName) && (
          <div className="tile__text">
            {showMessage && message && (
              <p className="tile__message">{message}</p>
            )}
            {showDisplayName && (
              <p className="tile__display-name">
                {message ? "- " + displayName : displayName}
              </p>
            )}
          </div>
        )}
      </div>
      {(created % 3 === 0 || created % 5 === 0) && (
        <div className="tile tile--filler" key={uid + "-filler-1"} />
      )}
      {created % 4 === 0 && (
        <div className="tile tile--filler" key={uid + "-filler-2"} />
      )}
    </>
  );
};

export default Tile;
