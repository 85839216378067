import { useEffect, useState } from "react";
import firebase from "../firebase";
import equal from "fast-deep-equal";
import transformUserData from "../utils/transformUserData";
import useSupporters from "./useSupporters";

export function useAuth() {
  const [user, setUser] = useState(null);
  const [supporters, setSupporter] = useSupporters();
  const isSupportersInitialized = Object.keys(supporters).length > 0;

  useEffect(() => {
    // Return the authStateChanged unsubscribe function
    return firebase.auth().onAuthStateChanged(newUser => {
      if (equal(user, newUser)) return;
      setUser(newUser);

      // If user is new, create user
      if (isSupportersInitialized && supporters && !supporters[newUser.uid]) {
        setSupporter(newUser.uid, transformUserData(newUser));
      }
    });
  });

  return [user];
}
